

:root {
  --background-color: #061121;
  --primary-color: #1199fa;
  --text-color: #D1D0D1;
  --font-xl: 42px;
  --font-lg: 24px;
  --font-md: 20px;
  --font-sm: 18px;
  --font-xs: 16px;
  --font-xxs: 14px;
  --screenshot-width: 400px;
  --screenshot-height: 500px;
}

@media (max-width: 1200px) {
  :root {
    --font-xl: 36px;
    --font-lg: 22px;
    --font-md: 18px;
    --font-sm: 16px;
    --font-xs: 14px;
    --font-xxs: 12px;
    --screenshot-width: 200px;
    --screenshot-height: 400px;
  }
}

@media (max-width: 992px) {
  :root {
    --font-xl: 32px;
    --font-lg: 20px;
    --font-md: 16px;
    --font-sm: 14px;
    --font-xs: 12px;
    --font-xxs: 10px;
    --screenshot-width: 180px;
    --screenshot-height: 360px;
  }
}
@media (max-width: 2600px) {
  :root {
    --font-xl: 32px;
    --font-lg: 20px;
    --font-md: 16px;
    --font-sm: 14px;
    --font-xs: 12px;
    --font-xxs: 10px;
    --screenshot-width: 180px;
    --screenshot-height: 360px;
  }
}

@media (max-width: 768px) {
  :root {
    --font-xl: 28px;
    --font-lg: 18px;
    --font-md: 14px;
    --font-sm: 12px;
    --font-xs: 10px;
    --font-xxs: 8px;
    --screenshot-width: 150px;
    --screenshot-height: 300px;
  }
  .app__screenshots-list {
    display: flex;
    overflow-x: auto;
    padding: 20px 0; /* Reduced padding */
    scroll-snap-type: x mandatory;
    width: 115%;
  }
  .app__screenshot {
    margin: 10px;
  }
}

body {
  
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 960px;
  padding: 0 15px;
  margin: auto;
}

.app__header {
  display: flex;
  flex-direction: row;
  margin-top: 64px;
  margin-bottom: 32px;
  align-items: center;
  flex-wrap: nowrap;
}

.app__logo-wrapper {
  display: inline-block;
  max-width: 250px;
  max-height: 250px;
  margin-right: 32px;
  flex: 0 0 auto;
}
.app__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.app__logo {
  width: 100%;
  height: auto;
  border: 1px solid white;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
}

@media (max-width: 1200px) {
  .app__logo-wrapper {
    max-width: 200px;
    max-height: 200px;
    margin-right: 25px;
  }
}

@media (max-width: 992px) {
  .app__logo-wrapper {
    max-width: 150px;
    max-height: 150px;
    margin-right: 20px;
  }
}
@media (max-width: 768px) {
  .app__logo-wrapper {
    max-width: 120px;
    max-height: 120px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .app__header {
    flex-direction: row;
    align-items: flex-start;
    text-align: center;
  }
  .app__infos {
    align-items: center;
    margin-left: 20px;
    text-align: left;
  }
  .app__name {
    white-space: normal;
    
  }
  .app__buttons{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}



.app__name {
  font-size: var(--font-xl);
  color: var(--primary-color);
  margin: 0;
  
}

.app__description {
  font-size: var(--font-lg);
}



.app__button-ios img, .app__button-android img {
  height: 40px;
  margin-right: 10px;
}

.app__screenshots {
  margin-top: 32px;
}

.app__section-title {
  font-size: var(--font-lg);
  color: var(--primary-color);
  margin-bottom: 9px;
}
.app__help{
  color:var(--primary-color)
  
}
.app__help__question{
  color:var(--text-color)
}
.app__screenshots-list {
  display: flex;
  overflow-x: auto;
  padding: 20px;
  scroll-snap-type: x mandatory;
  width: 104%;
}

.app__screenshot {
  max-width: var(--screenshot-width);
  height: var(--screenshot-height);
  object-fit: contain;
  border-radius: 16px;
  margin-right: 15px;
  flex-shrink: 0;
  border: 2px solid white;
}
.app__screenshot:hover {
  transform: scale(1.05);
}
.app__description-section {
  margin-top: 64px;
}

.footer {
  margin-top: 64px;
  padding: 16px 0;
  font-size: var(--font-xs);
  text-align: center;
}
